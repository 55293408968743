// This is a manifest file that'll be compiled into including all the files listed below.
// Add new JavaScript/Coffee code in separate files in this directory and they'll automatically
// be included in the compiled file accessible from http:#example.com/assets/application.js
// It's not advisable to add code directly here, but if you do, it'll appear at the bottom of the
// the compiled file.
//
import '../shared/sentry';
import '../shared/jquery-global'
import 'slick-carousel/slick/slick';

import '@materializecss/materialize/dist/js/materialize';

import { showDragMap, showMap } from './map';
//import 'spree/frontend/spree_geocoding'
import 'users';
import './feedback';
import '../frontend/phone';
import 'jquery.raty';
import 'ratyrate';
//window.List = import 'list.js/dist/list.min'
import '../frontend/howmuch_shops';
import '../frontend/custom';
import '../frontend/address';
import '../frontend/display_map';
import '../frontend/editable-cart';
import 'jticker/jquery.jticker.min';
import '../frontend/country_select';
// import '../frontend/checkout/cart'

import '../frontend/location-box';
import '../frontend/checkout/cart';
// import 'slick-carousel/slick/slick.min'
//= require_self

import './print-order';

import { notifyJsMessages } from '../shared/mobile-communication';
import { elementsOnEvent, showElements } from './jquery-alternative';
document.addEventListener('DOMContentLoaded', () => notifyJsMessages());

//#####################################
window.uninitialize_product_shop_sliders = function(reinit) {
  const elements = ['.featured-store-slider', '.featured-brands-slider', '.featured-cats-slider'];
  elements.forEach(function(element) {
    const $element = $(element);
    if (!!reinit && !!$element.unslick) { $element.unslick(); }
  });
};

window.initialize_product_shop_sliders = function(reinit) {
  const elements = ['.featured-store-slider', '.featured-brands-slider', '.featured-cats-slider'];
  elements.forEach(function(element) {
    const $element = $(element);
    if ($element.length > 0) {
      if (!!reinit && !!$element.unslick) { $element.unslick(); }
      $element.slick({
        dots: false,
        autoplay: true,
        infinite: false,
        speed: 300,
        slidesToShow: 6,
        slidesToScroll: 4,
        prevArrow: '<button type=\'button\' class=\'slick-prev pull-left\'><i class=\'material-icons\'>keyboard_arrow_left</i></button>',
        nextArrow: '<button type=\'button\' class=\'slick-next pull-right\'><i class=\'material-icons\'>keyboard_arrow_right</i></button>',
        responsive: [
          {
            breakpoint: 768,
            settings: {
              centerMode: true,
              centerPadding: '40px',
              slidesToShow: 4
            }
          },
          {
            breakpoint: 480,
            settings: {
              centerMode: true,
              centerPadding: '40px',
              slidesToShow: 2
            }
          }
        ]
      });
    }
  });
  // M.Carousel.init(document.querySelectorAll('.cat-carousel'), {
  //   dist: 0,
  //   shift: 0,
  //   padding: 30,
  //   noWrap: false
  // }
  // );
  if (document.querySelectorAll(".products-slider").length > 0) {
    $('.products-slider').slick({
      dots: false,
      infinite: false,
      speed: 300,
      slidesToShow: 4,
      slidesToScroll: 4,
      prevArrow: '<button type=\'button\' class=\'slick-prev pull-left\'><i class=\'material-icons\'>keyboard_arrow_left</i></button>',
      nextArrow: '<button type=\'button\' class=\'slick-next pull-right\'><i class=\'material-icons\'>keyboard_arrow_right</i></button>',
      responsive: [
        {
          breakpoint: 768,
          settings: {
            centerMode: true,
            centerPadding: '40px',
            slidesToShow: 4
          }
        },
        {
          breakpoint: 480,
          settings: {
            centerMode: true,
            centerPadding: '40px',
            slidesToShow: 1
          }
        }
      ]
    });
  }
  $('.homepage-slider').slick({
    autoplay: true,
    dots: true,
    arrows: false,
    autoplaySpeed: 5000
  });

  $('.product-slider').slick({
    infinite: true,
    autoplay: true,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  });

  const counter_product = 3;
  let counter_store = 2;

  const total_page = parseInt(document.querySelector("#total_page_number")?.value);
  let counter_store_prev = parseInt(document.querySelector("#total_page_number")?.value);
  document.querySelector('.product-slider')?.querySelector('.slick-next')?.addEventListener('click', function() {
    counter_store += 1;
    if ((counter_store > 2) && (counter_store <= (total_page + 1))) {
      fetch('/api/v2/store_variants', {
        headers: {"Content-Type": "text/javascript"},
        body: JSON.stringify({
          'page': counter_store,
          'per_page': 4
        })
      }).then(response => response.text().then(data => eval(data)));
    }
  });


  document.querySelector('.product-slider')?.querySelector('.slick-prev')?.addEventListener('click', function() {
    if (counter_store_prev <= 1) {
      counter_store_prev = 1;
    }
    fetch('/api/v2/store_variants', {
      headers: {"Content-Type": "text/javascript"},
      body: JSON.stringify({
        'page': counter_store_prev,
        'per_page': 4
      })
    }).then(response => response.text().then(data => eval(data)));
    counter_store_prev -= 1;
  });

  elementsOnEvent("[data-target^='#feedback-modal-']", 'click', function() {
    //      e.preventDefault();
    $(this.dataset.target).modal('open');
  });
};

//#####################################3

window.showDragMap = showDragMap; //used by update_state.js
window.showMap = showMap; //used by update_state.js
document.addEventListener('DOMContentLoaded', function() {

  let mapElement = document.getElementById('map-delivery');
  if (!!mapElement) {
    showMap(mapElement);
  }
  mapElement = document.getElementById('map');
  if (!!mapElement) {
    showDragMap(mapElement, document.getElementById('bill-latitude'), document.getElementById('bill-longitude'));
  }

  if (!!document.getElementById('search-products')) {
    let controller = null;
    document.getElementById('search-products').addEventListener('keyup', function(event) {
      if (!!document.user_typing) {
        clearTimeout(document.user_typing);
      }
      document.user_typing = setTimeout((function() {
        const {
          api_key
        } = Rails;
        const auth_token = $('meta[name="csrf-token"]').attr('content');
        const searched = document.querySelector('#search-products');
        const search_word = document.querySelector('#search-products')?.value;
        if (controller !== null) {          
          controller.abort();
        } else {
          controller = new AbortController();
        }
        const signal = controller.signal;          
        fetch('/api/v2/taxons.json', {
          signal,
          body: JSON.stringify({
            'q': searched.value,
            'taxonomy_id': 28
          })
        }).then(response => response.json().then((data) => {
          if (!!data && data.taxons.length > 0) {
            const {
              taxons
            } = data;
            document.querySelectorAll('option[id^=\'category_\']').forEach(el => el.remove());
            let i = 0;

            if (document.querySelector("#form1")?.querySelector("#search_box")) {
              document.querySelector('#form1').querySelector('#search_box').remove();
            }

            document.querySelector("#form1").append("<ul id='search_box'></ul>");
            while (i < taxons.length) {
              if (!!taxons[i].parent_id) {
                document.querySelector("#form1").querySelector('#search_box').ins('<li><a href=\'/c/' + taxons[i].permalink + '?keyword=' + search_word + '\'><strong>' + search_word + '</strong> in ' + taxons[i].name + '</a></li>');
              }
              //$('#form1').trigger 'new-search-suggestions-loaded'
              i++;
            }
            showElements('.option')
          } else {
            if (document.querySelectorAll("#form1").querySelector("#search_box").length > 0) {
              document.querySelector('#form1').querySelector('#search_box').remove();
            }
          }
        }));
      }), 1000);
    }
    );
  }


  $('.dropdown-trigger').dropdown({
    inDuration: 300,
    outDuration: 225,
    constrainWidth: false,
    hover: false,
    gutter: -15,
    belowOrigin: true,
    alignment: 'left',
    stopPropagation: false
  });
  initialize_product_shop_sliders();
  document.querySelector('#city_name')?.addEventListener('change', () => document.querySelector('#city_name').value = '');
  $('.ticker').jTicker();
  //$('select').select()
  $('.modal').modal();
  $('.dropdown-button').dropdown({
    inDuration: 300,
    outDuration: 225,
    constrainWidth: false,
    hover: true,
    gutter: 0,
    belowOrigin: false,
    alignment: 'left',
    stopPropagation: false
  });
});
