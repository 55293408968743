document.addEventListener('DOMContentLoaded', getGeoLocation)

const positionOptions = {
  enableHighAccuracy: true,
  timeout: 5000,
  maximumAge: 90000
};

var getGeoLocation = function() {
  if (((window.location.pathname === "/") || (window.location.pathname === "/shops" || window.location.pathname === "/stores")) && !!navigator.geolocation && (typeof (navigator.geolocation) !== 'undefined')) {
    navigator.geolocation.getCurrentPosition(setGeoCookie, errorSetGeoCookie, positionOptions);
  }
};

var errorSetGeoCookie = err => console.log(`ERROR(${ err.code }): ${ err.message }`);

var setGeoCookie = function(position) {
  const cookie_val = position.coords.latitude + "|" + position.coords.longitude;
  const lat = position.coords.latitude;
  const lng = position.coords.longitude;
  //  get_current_city(lat,lng)
  document.cookie = "lat_lng=" + escape(cookie_val);


  fetch(window.location.href, {
    body: JSON.stringify({
      lat,
      lng
    }),
    headers: {
      'Content-Type': 'application/json'
    }
  }).then(response => response.text().then((html) => {
    if (document.querySelectorAll(".geo-preferences").length > 0) {
      document.querySelector('.geo-preferences').replaceChildren();
    }
    document.querySelector('.geo-preferences').innerHTML = html;
    window.initialize_product_shop_sliders();
    const counter_product = 3;
    let counter_store = 2;

    const total_page = parseInt(document.querySelector("#total_page_number")?.value);
    let counter_store_prev = parseInt(document.querySelector("#total_page_number")?.value);
    document.querySelector('.product-slider')?.querySelector('.slick-next')?.addEventListener('click', function() {
      counter_store += 1;
      if ((counter_store > 2) && (counter_store <= (total_page + 1))) {
        fetch('/api/v2/store_variants', {
          headers: {'Content-Type': 'text/javascript'},
          body: JSON.stringify({
            'page': counter_store,
            'per_page': 4
          })
        }).then(response => response.text().then(data => eval(data)));
      }
    });


    document.querySelector('.product-slider')?.querySelector('.slick-prev')?.addEventListener('click', function() {
      if (counter_store_prev <= 1) {
        counter_store_prev = 1;
      }
      fetch('/api/v2/store_variants', {
        headers: {'Content-Type': 'text/javascript'},
        body: JSON.stringify({
          'page': counter_store_prev,
          'per_page': 4
        })
      }).then(response => response.text().then(data => eval(data)));
      counter_store_prev -= 1;
    });
  }));
};

//get_current_city = (lat, lng)->
//
//  geocoder = new google.maps.Geocoder();
//  latlng = new google.maps.LatLng(lat, lng)
//  geocoder.geocode({'latLng': latlng}, (results, status)->
//    if (status == google.maps.GeocoderStatus.OK)
//      console.log(results)
//      if (results[1])
//        console.log(results[0].formatted_address)
//        for address_component in results[0].address_components
//          for component_type in address_component.types
//            if component_type == "administrative_area_level_1"
//              city = address_components
//              console.log(city)
//              break
